<template>
  <div class="containerBox">
    <div class="searchBar">
      <input class="form-control" type="text" placeholder="장치이름을 입력하세요" @input="searchData($event)" />
      <select class="selects" v-model="selected" @change="onClickTab(selected)">
        <option value="">장소를 선택하세요</option>
        <option v-for="(site, index) in siteTables" :value="index" :key="index">{{ site.title }}</option>
      </select>
    </div>

    <div class="searchResult" >
      <div class="group" v-for="(site, index) in siteTables" :key="index">
        <div class="group-item" v-for="(data, index) in site.data" :key="index" @click="sendEventData(data.guid)">
          
            <div class="imgWrapper" :style="{ border: calcBorderColor(data.level) }" :onclick="getlink(data)">
                <div class="img">
                  <vue-ellipse-progress :progress="Number(getSensorData(data))" :size="180" max="4" thickness="15" dot="15" fontSize="1.4rem" color="#1fb73b">
                      <span slot="legend-value"> ppm</span>
                  </vue-ellipse-progress>
                  <div class="dataWrapper" style="width: 100%;">
                    <div v-if="data.level == 2" class="devicetitle" style="color: #FF8C00">{{ data.name }}</div>
                    <div v-else-if="data.level == 3" class="devicetitle" style="color: #E50000">{{ data.name }}</div>
                    <div v-else class="devicetitle" >{{ data.name }}</div>
                  </div>
                </div>
              <div class="rfstatus" v-html="findRFImg(data)"></div>
            </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import utils from '@/services/utils';
import Device from "@/views/srds/Device.vue";

import { VueEllipseProgress } from 'vue-ellipse-progress'

export default {
  name: 'TOCDevice',
  extends: Device,
  components: {
        VueEllipseProgress
  },
  methods: {
    getlink(data){
      var row = data;
      return "location.href='#/{0}device/{1}'".format(this.prefix, row.guid)
    },
    getSensorData(data) {
      var result = "";

      if (_.isUndefined(data.sensor)){
          console.log("isUndefined");
          return;
      }

      data.sensor.data.forEach(function(e){
        if(e.type === 48 || e.type === 1)
          result = e.value;
      })

      return Number(result).toFixed(4);
    },    
  }
}
</script>

<style tocdevice>
.devicetitle {
  font-size: 14px;
  padding: 1%;
  font-weight: bold;
  position: relative;
  top: 2%;
  left: 2%;
  z-index: 1;
  color: white;
  background-color: rgb(0,0,0,0.7);
  border-radius: 4px;
}
.rfstatus {
  /* margin-left: 20px; */
  position: absolute;
  top: 1.2%;
  right: -5%;
  z-index: 1;
}

</style>