<template>
  <div>
    <CRow>
      <CCol v-for="(item, index) in data" :key="index" col="12" xl="3" md="6">
            <CCard class="card" @click="onClick(item)">
                <div class="contentWrapper">
                    <div class="content">
                        <h5 class="content__title">{{ item.title }}</h5>
                        <span class="content__value">{{ item.val }}</span>
                    </div>
                    <div v-if="item.val >= 1 && item.key == 'DW'" class="icon">
                        <i :class="item.icon" class="fa-shake" :style="{ color:item.color }"></i>
                    </div>
                    <div v-else class="icon">
                      <i :class="item.icon" :style="{ color:item.color }"></i>
                    </div>
                </div>
            </CCard>
        </CCol>
    </CRow>
  </div>
</template>

<script>
import Summation from '@/views/dashboard/Summation'

export default {
  name: 'SRDSSummation',
  extends: Summation,
  data () {
    return {
      data: [
        { title: "전체", key: 'DG', val: 1, color: '#039BE5', icon: 'fa fa-home'}, 
        { title: "정상", key: 'DN', val: 0, color: '#2F7332',icon: 'fa fa-check-square'}, 
        { title: "주의", key: 'DC', val: 0, color: '#FF8C00',icon: 'fa fa-warning' }, 
        { title: "경고", key: 'DW', val: 0, color: '#E50000',icon: 'fa fa-hill-rockslide'},
      ]
    }
  },
  methods: {
    update(count, events) {
      this.data.forEach(elem => elem.val = 0);

      events.forEach(row => {
        var key = 'BG';
        var item = _.find(this.data, { key: key })
        switch (row.sensor_type) {
          case 1:
            key = row.level == 1 ? 'BN' : row.level == 2 ? 'BC' : 'BW';
            item = _.find(this.data, { key: key });
            item.val += 1;
            break;
          case 48:
            key = row.level == 2 ? 'DC' : 'DW';
            item = _.find(this.data, { key: key });
            item.val += 1;
            break;
        }
      })

      var item = _.find(this.data, { key : 'DN' })
      item.val = count - (_.find(this.data, {key:'DC'}).val + _.find(this.data, {key:'DW'}).val)

      item = _.find(this.data, { key : 'DG' })
      item.val = (_.find(this.data, { key : 'DN' }).val + _.find(this.data, {key:'DC'}).val + _.find(this.data, {key:'DW'}).val)
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.wrap {
  color: white;
  position: relative;
  width: 150px;
  height: 100px;
  margin: 5px;
  border-radius: 4px;
  transition: box-shadow .3s;
  background-color: #eee;
  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
  }
}
.title {
  position: absolute;
  top: 5%;
  width: 100%;
  text-align: center;
}
.sum_val {
  position: absolute;
  font-size: 38px;
  font-weight: 600;
  top: 35%;
  left: 50%;;
  transform: translateX(-50%);
  bottom: -30px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background-color: rgba(255,255,255,0.8);
  border-radius:100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.sum_val span {
  margin: auto;
  color: #262626;
}
@media (max-width: 1700px) {
  .wrap {
    width: 120px;
  }
}
@media (max-width: 1326px) {
  .wrap {
    width: 100px;
  }
}
@media (max-width: 501px) {
  .wrap {
    width: 100px;
  }
  .container{
    width: 75%;
  }
}
@media (max-width: 365px) {
  .wrap {
    width: 100px;
  }
  .container{
    width: 100%;
  }
}

.card {
    border-radius: 12px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.15), 
            0 1px 1px rgba(0,0,0,0.15), 
            0 3px 3px rgba(0,0,0,0.15), 
            0 6px 6px rgba(0,0,0,0.15);
    cursor: pointer;
}
.contentWrapper {
    display: flex;
    position: relative;
    .content {
        margin: 3%;
        width: 56%;
        .content__title {
        }
        .content__value {
          margin-left: 4%;
          font-size: 22px;
          font-weight: bold;
        }
      }
      .icon {
        margin: auto;
        font-size: 48px;
    }
}
</style>